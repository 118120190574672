import { createStore } from "vuex";
import { generateEmptyMap, convertMapA, checkReq, checkSet, checkBuildings, getAllBuildingInMap } from "@/assets/scripts/map";
import { useToast } from "vue-toast-notification";
import { deleteBuilding } from "@/assets/scripts/helper";
let toast = useToast({ position: "top" });

export default createStore({
  state: {
    selected: { x: null, y: null, tile: null },
    map: null,
    brush: null,
    build: null,
    zoom: 1,
    info: null,
    grid: true,
    res: [],
    timer: null,
    speed: 100,
    bList: [],
  },
  getters: {},
  mutations: {
    resetRes(state) {
      state.res = JSON.parse(
        JSON.stringify([
          { id: "scrap", num: 70, name: "Scrap" },
          { id: "waste", num: 70, name: "Waste" },
          { id: "food", num: 70, name: "Food" },
        ])
      );
    },
    clearTimer(state) {
      clearInterval(state.timer);
      state.timer = null;
    },
    setTimer(state, val) {
      state.timer = val;
    },
    setBuildings(state, val) {
      state.buildings = val;
    },
    toggleGrid(state) {
      state.grid = !state.grid;
    },
    setInfo(state, val) {
      state.info = val;
    },
    changeZoom(state, val) {
      state.zoom = val;
    },
    selectBuilding(state, val) {
      if (val == null) {
        state.build = null;
      } else if (state.build && val.id == state.build.id) {
        state.build = null;
      } else {
        state.build = val;
      }
      state.selected = null;
    },
    removeBuilding(state) {
      deleteBuilding(state.map.map, state.res, state.selected);
    },

    resetSelected(state) {
      state.selected = null;
    },
    setSelected(state, payload) {
      if (payload.x == state.selected?.x && payload.y == state.selected?.y) {
        state.selected.x = null;
        state.selected.y = null;
      } else {
        state.selected = { x: payload.x, y: payload.y };
      }
    },
    setTileBrush(state, val) {
      state.brush = val;
    },
    setTile(state, tile) {
      if (state.selected.x != null && state.selected.y != null) state.map[state.selected.x][state.selected.y] = { tile };
    },
    setMap(state, map) {
      state.map = map;
    },
  },
  actions: {
    startTimer({ commit, state, dispatch }) {
      if (state.timer == null) {
        let timer = setInterval(() => dispatch("update"), 100);
        commit("setTimer", timer);
      }
    },
    update({ state }) {
      let buidings = getAllBuildingInMap(state.map.map);
      for (let b of buidings) {
        if (b.prod) {
          for (let prod of b.prod) {
            state.res.find((x) => x.id == prod.id).num += prod.num;
          }
        }
      }
    },
    checkAllBuildings({ state, commit }) {
      let buildings = checkBuildings(state.map.map);
      commit("setBuildings", buildings);
    },
    generateMap({ commit }, { x, y }) {
      let map = generateEmptyMap(x, y);
      commit("setMap", map);
    },
    fireAction({ state, commit, dispatch }, cell) {
      if (state.build) {
        if (state.build == "remove") {
          state.selected = cell;
          commit("removeBuilding");
        } else if (checkReq(cell, state.build, state.map.map, toast)) {
          if (cell.building == null) {
            let afford = true;
            for (let cost of state.build.cost) {
              if (cost.num > state.res.find((x) => x.id == cost.id).num) {
                afford = false;
                toast.warning("Not enogh " + cost.id);
                break;
              }
            }

            if (afford) {
              for (let cost of state.build.cost) {
                state.res.find((x) => x.id == cost.id).num -= cost.num;
              }
              cell.building = JSON.parse(JSON.stringify(state.build));
              checkSet(cell, state.map.map);
            }
          }
        }
        dispatch("checkAllBuildings");
      } else {
        if (cell.building?.set) {
          state.selected = cell;
        } else {
          if (state.selected == cell) {
            state.selected = null;
          } else if (cell.building) {
            state.selected = cell;
          } else {
            state.selected = null;
          }
        }
      }
    },
    selectLevel({ commit, dispatch }, map) {
      map.map = convertMapA(map.map);
      commit("setMap", map);
      commit("resetSelected");
      commit("resetRes");
      dispatch("startTimer");
      this.$router.push("/game");
    },
  },
  modules: {},
});
