<template>
  <router-view />
</template>

<style>
.btn {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background-color: #2ecc71;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.3s ease;
  margin: 4px;
}

.btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  border: 4px solid #27ae60;
  pointer-events: none;
}

.btn:hover {
  background-color: #27ae60;
}

.btn:focus {
  outline: none;
}

.btn:active {
  background-color: #1d8348;
}

.flex {
  display: flex;
}


.flexy {
  display: flex;
  flex-direction: column;
}




body {
  padding: 0px;
  margin: 0px;
}
</style>
