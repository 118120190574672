<template>
  <div class="home">
    <GameMenu />
  </div>
</template>

<script>
import GameMenu from '@/components/GameMenu.vue';

// @ is an alias to /src

export default {
  name: "HomeView",
  components: { GameMenu }
}
</script>
