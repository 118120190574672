import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "vue-toast-notification/dist/theme-sugar.css";
import ToastPlugin from "vue-toast-notification";

store.$router = router;

createApp(App).use(router).use(store).use(ToastPlugin).mount("#app");
