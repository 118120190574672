import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/build",
    name: "build",
    component: () => import(/* webpackChunkName: "about" */ "../views/BuildView.vue"),
  },
  {
    path: "/start",
    name: "start",
    component: () => import(/* webpackChunkName: "about" */ "../views/StartView.vue"),
  },
  {
    path: "/game",
    name: "game",
    component: () => import(/* webpackChunkName: "about" */ "../views/GameView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
