import tags from "@/assets/json/tiles.json";
import patterns from "@/assets/json/sets.json";
import buildings from "@/assets/json/buildings.json";
import breq from "@/assets/json/breq.json";

export function generateEmptyMap(x, y) {
  var map = new Array(x);
  for (var i = 0; i < x; i++) {
    map[i] = new Array(y).fill({ tile: "air", x: null, y: null });
  }
  return map;
}

export function convertMapB(map) {
  return map.map((row) => {
    return row.map((cell) => {
      const matchingTag = tags.find((tag) => tag.id === cell.tile);
      if (matchingTag) {
        return matchingTag.tag;
      } else {
        return "";
      }
    });
  });
}

export function convertMapA(map) {
  const convertedMap = map.map((row, i) => {
    return row.map((cell, j) => {
      const matchingTag = tags.find((tag) => tag.tag === cell);
      if (matchingTag) {
        const convertedCell = {
          ...matchingTag,
          x: j,
          y: i,
        };
        return convertedCell;
      } else {
        return "";
      }
    });
  });

  return convertedMap;
}

export function checkReq(cell, build, map, t) {
  let err;

  for (let req of build.req) {
    let pass = true;

    if (req.on && map[cell.y][cell.x].id != req.on) {
      pass = false;
      err = "must be placed on " + req.on;
      continue;
    }

    if (req.down2 != null) {
      if (map.length <= cell.y + 2) {
        pass = req.down2 == "ground";
      } else if (req.down2 == "solid") {
        pass = map[cell.y + 2][cell.x]?.building?.solid;
      } else if (req.down2 == "farm") {
        pass = map[cell.y + 2][cell.x]?.building?.id == "farm";
      } else if (map[cell.y + 2][cell.x].id != req.down2) {
        pass = false;
        err = "must be placed above 2 " + req.down2;
        continue;
      }
    }

    if (req.down != null) {
      if (map.length <= cell.y + 1) {
        pass = req.down == "ground";
      } else if (req.down == "solid") {
        pass = map[cell.y + 1][cell.x]?.building?.solid;
      } else if (req.down == "farm") {
        pass = map[cell.y + 1][cell.x]?.building?.id == "farm";
      } else if (map[cell.y + 1][cell.x].id != req.down) {
        pass = false;
        err = "must be placed above " + req.down;
        continue;
      }
    }

    if (req.up != null) {
      if (cell.y - 1 <= 0) {
        pass = req.up == "air" && pass;
      } else if (req.up == "solid") {
        pass = map[cell.y - 1][cell.x]?.building?.solid;
      } else if (map[cell.y - 1][cell.x].id != req.up) {
        pass = false;
        err = "must be placed below " + req.up;
        continue;
      }
    }

    if (req.left != null) {
      if (cell.x - 1 < 0) {
        pass = req.left == "ground" && pass;
      } else if (req.left == "solid") {
        pass = map[cell.y][cell.x - 1]?.building?.solid;
      } else if (map[cell.y][cell.x - 1].id != req.left) {
        pass = false;
        err = "must be placed left " + req.left;
        continue;
      }
    }

    if (req.right != null) {
      if (cell.y + 1 >= map.length) {
        pass = req.left == "ground" && pass;
      } else if (req.left == "solid") {
        pass = map[cell.y][cell.x + 1]?.building?.solid;
      } else if (map[cell.y][cell.x + 1].id != req.left) {
        pass = false;
        err = "must be placed right " + req.left;
        continue;
      }
    }

    if (pass) {
      return true;
    }
  }
  t.warning(err);
  return false;
}

// Function to check if any cell in the grid matches the mushroomFarm configuration
export function checkSet(building, grid) {
  for (let pattern of patterns) {
    const patternHeight = pattern.config.length;
    const patternWidth = pattern.config[0].length;

    let match = true;
    for (let row = 0; row < patternHeight; row++) {
      for (let col = 0; col < patternWidth; col++) {
        match = checkFromPoint(grid, building, pattern.config, row, col);
        if (match) {
          let items = getAllMatchedCells(row, col, pattern.config, grid, building);
          setPattern(items, pattern);
        }
      }
    }
  }
  return false;
}

function checkFromPoint(grid, building, pattern, y, x) {
  const gridHeight = grid.length;
  const gridWidth = grid[0].length;

  const patternHeight = pattern.length;
  const patternWidth = pattern[0].length;

  let match = true;
  for (let i = 0; i < patternHeight; i++) {
    for (let j = 0; j < patternWidth; j++) {
      const gridX = building.x + j - x;
      const gridY = building.y + i - y;

      if (gridX >= gridWidth || gridY >= gridHeight || gridX < 0 || gridY < 0) {
        match = false;
        break;
      }

      if (pattern[i][j]) {
        let b = buildings.find((x) => x.tag == pattern[i][j]);
        if (grid[gridY][gridX].building?.id != b.id || grid[gridY][gridX].building?.set != null) {
          match = false;
          break;
        }
      }
    }
  }
  return match;
}

function getAllMatchedCells(x, y, pattern, grid, building) {
  const patternHeight = pattern.length;
  const patternWidth = pattern[0].length;
  let items = [];
  for (let row = 0; row < patternHeight; row++) {
    for (let col = 0; col < patternWidth; col++) {
      if (pattern[row][col]) {
        items.push(grid[building.y + row - x][building.x + col - y]);
      }
    }
  }
  return items;
}

function setPattern(items, pattern) {
  for (let item of items) {
    item.building.set = JSON.parse(JSON.stringify(pattern));
    let copy = JSON.parse(JSON.stringify(items));
    item.building.set.items = copy;
  }
}

export function checkBuildings(grid) {
  let counts = countBuildings(grid);
  let list = [];
  for (let item of breq) {
    let match = true;
    for (let req of item.req) {
      if (counts[req.id] == undefined || counts[req.id] < req.num) {
        match = false;
        break;
      }
    }
    if (match) {
      list.push(buildings.find((x) => x.id == item.id));
    }
  }
  return list;
}

function countBuildings(arr) {
  const countObj = {};
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      const id = arr[i][j].building?.id;
      if (id == undefined) {
        continue;
      }
      if (countObj[id]) {
        countObj[id] += 1;
      } else {
        countObj[id] = 1;
      }
    }
  }
  return countObj;
}

export function getAllBuildingInMap(map) {
  const bList = [];
  for (let i = 0; i < map.length; i++) {
    for (let j = 0; j < map[i].length; j++) {
      if (map[i][j].building != undefined) bList.push(map[i][j].building);
    }
  }
  return bList;
}
