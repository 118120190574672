export function deleteBuilding(grid, res, selected) {
  let building = selected.building;

  //return if no building selected
  if (!building) {
    return;
  }

  //Remove set
  if (building.set) {
    for (let item of building.set.items) {
      delete grid[item.y][item.x].building.set;
    }
  }

  //fall buildings

  //restore 100% resources
  for (let cost of building.cost) {
    res.find((x) => x.id == cost.id).num += cost.num;
  }

  //Delete Building
  selected.building = null;
  //Reset selection

  if (selected.id == "air") {
    for (let i = selected.y; i >= 0; i--) {
      if (i == 0) {
        grid[i][selected.x].building = null;
      }

      if (grid[i][selected.x].id == "air") {
        if (grid[i][selected.x].building != null || i == selected.y) {
          grid[i][selected.x].building = grid[i - 1][selected.x].building;
        }
      }
    }
  } else if (selected.id == "ground") {
    for (let i = selected.y; i < grid.length; i++) {
      if (i >= grid.length - 1) {
        grid[i][selected.x].building = null;
      }

      if (grid[i][selected.x].id == "ground") {
        if (grid[i][selected.x].building != null || i == selected.y) {
          grid[i][selected.x].building = grid[i + 1][selected.x].building;
        }
      }
    }
  }

  selected = null;
}
