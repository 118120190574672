<template>
    <div class="main-menu">
        <nav>
            <button class="btn" @click="$router.push('/start')">Start</button>
            <button class="btn" @click="$router.push('/load')">Load</button>
            <button class="btn" @click="$router.push('/build')">Map Editor</button>
        </nav>
    </div>
</template>
  
<style lang="scss" scoped>
.main-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

nav {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn {
    padding: 10px 20px;
    margin: 10px;
    font-size: 24px;
}
</style>
  